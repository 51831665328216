import { useEffect, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import Layout from 'manage-tritag/components/layout'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import EnhancedTableFilter from 'manage-tritag/components/table/enhanced-table/enhanced-table-filter'
import { QueryParams } from 'manage-tritag/services/api/endpoints/associations'
import Spinner from 'manage-tritag/components/loading/spinner'
import {
  Incident,
  useLazyGetIncidentsQuery,
} from 'manage-tritag/services/api/endpoints/incidents'

interface HeadCell {
  disablePadding: boolean
  id: keyof Incident
  label: string
  numeric: boolean
}

const IncidentPage = () => {
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
  })
  const [trigger, result] = useLazyGetIncidentsQuery()

  const { isLoading: dataLoading, data: incidents } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  const headCells: HeadCell[] = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'competition',
      numeric: false,
      disablePadding: false,
      label: 'Competition',
    },
    {
      id: 'association',
      numeric: false,
      disablePadding: false,
      label: 'Association',
    },
    {
      id: 'participant',
      numeric: false,
      disablePadding: false,
      label: 'Participant',
    },
    {
      id: 'assessment',
      numeric: false,
      disablePadding: false,
      label: 'Outcome',
    },
  ]
  const bodyCells = [
    'id',
    'competition',
    'association',
    'participant',
    'assessment',
  ]
  return (
    <Layout label1="Incidents" url="/associations">
      {dataLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        incidents && (
          <>
            <EnhancedTableFilter params={params} setParams={setParams} />
            <EnhancedTable
              tableTitle="Incidents"
              uniqueIdentifier="_id"
              rows={incidents.map((incident, index) => ({
                ...incident,
                id: index + 1,
                _id: incident._id,
                competition: incident.competition.name,
                association: incident.association.name,
                participant: incident.participant
                  ? incident.participant.firstName +
                    incident.participant.lastName
                  : '',
                assessment: incident.outcome.assessment,
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(IncidentPage)
